import React from 'react';
import './barchart.scss';

export default ({ hoveredBar }) => {
  return (
    <div className="Tooltip">
      <div>
        {hoveredBar.category}:&nbsp;{hoveredBar.value}
      </div>
    </div>
  );
};
