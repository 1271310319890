import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ReadMore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: this.props.limit ? this.props.limit : 50,
      read: true,
    }
    this.onToggle = this.onToggle.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if ((props.limit || 50) !== state.limit) {
      return { limit: props.limit || 50 }
    }
    return null
  }

  onToggle(e) {
    e.stopPropagation()
    this.setState((prevState) => ({
      read: !prevState.read,
    }))
  }

  render() {
    if (this.props.children) {
      if (this.props.type === 'text') {
        if (this.props.children.length <= this.state.limit) {
          return <div className={`read-more ${this.props.className ? this.props.className : ''}`}>{ this.props.children }</div>
        }
        return <div className={`read-more ${this.props.className ? this.props.className : ''}`}> {this.state.read && this.props.children.substring ? `${this.props.children.substring(0, this.state.limit)}....` : this.props.children} <div role="presentation" className="handicon" onClick={this.onToggle}> {this.state.read ? 'Read more' : 'Read less'}</div></div>
      }
      if (Array.isArray(this.props.children) === false || (Array.isArray(this.props.children) && this.props.children.length <= this.state.limit)) {
        return (
          <div className={`read-more ${this.props.className ? this.props.className : ''}`}> { this.props.children } </div>
        )
      }
      return (
        <div className={`read-more ${this.props.className ? this.props.className : ''}`}> {this.state.read ? this.props.children.filter((d, i) => i < this.state.limit) : this.props.children} <span role="presentation" className="handicon" onClick={this.onToggle}>{this.state.read && this.props.children.length > this.state.limit ? `(+${this.props.children.length - this.state.limit}) ` : ''} {this.state.read ? 'Read more' : 'Read less'}</span></div>
      )
    }
    return <div data="" {...(this.props.message ? { message: this.props.message } : {})} />
  }
}

ReadMore.propTypes = {
  limit: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  message: PropTypes.string,
  type: PropTypes.string,
}

ReadMore.defaultProps = {
  limit: 50,
  className: '',
  type: '',
  message: undefined,
}
export default ReadMore
