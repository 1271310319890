import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import auth0 from 'auth0-js'

import { removeSession } from '../Status/logic'

const Logout = () => {
  const webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
  })
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(removeSession())
    webAuth.logout({
      returnTo: process.env.appUrl,
      clientID: process.env.auth0.clientId,
    })
  }
  const session = useSelector((state) => state.session)
  if (session) {
    return (
      <div
        role="presentation"
        onClick={logout}
        // onClick={() => dispatch(logoutFetchAction())}
        className="navbar-group-right-item"
      >
        Logout
      </div>
    )
  }
  return null
}

export default Logout
