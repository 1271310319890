import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3-5.16.0';
import { Modal } from 'antd';
import { parse, stringify } from 'query-string';
import Axios from 'axios';
import Tooltip from './Tooltip';
import ShowStudiesData from '../../../app/container/ShowStudiesData';
import ShowCentersData from '../../container/ShowCentersData';
import { getCookie, getFormattedDate } from '../../../utils';

const colors = d3.scaleOrdinal().range(['#d3d800', '#e4e766', '#e9eb7f', '#edef99', '#4e565b',
  '#637b88', '#83888c',
  '#94999c',
  '#a6aaad',
  '#b8bbbd',
  '#8A0050',
  '#ad4c84',
  '#c47fa7',
  '#d099b9',
  '#003e6e',
  '#4c7799',
  '#668ba8',
  '#7f9eb6',
  '#99b1c5',
]);

const capacityColors = d3.scaleOrdinal().range(['#D3D800']);

const XAxis = ({
  bottom, left, height, scale, hideLabels,
}) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .call(d3.axisBottom(scale))
      .selectAll('text')
      .style('text-anchor', 'start')
      .style('word-break', 'break-all')
      .style('font-size', 10)
      .attr('dx', '.6em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(45)');

    if (hideLabels) {
      d3.selectAll('.x.axis>.tick')
        .text((d) => (d));
    } else {
      d3.selectAll('.x.axis>.tick')
        .append('title')
        .text((d) => (d));
    }
  });

  return <g className="axis x" ref={axis} transform={`translate(${left}, ${height - bottom})`} />;
};

const YAxis = ({ top, left, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .call(d3.axisLeft(scale))
  });

  return <g className="axis y" ref={axis} transform={`translate(${left}, ${top})`} />;
};

const Rect = ({
  data, index, x, y, height, top, bottom, onMouseOverCallback, onMouseOutCallback, onClickCallback, flag,
}) => {
  return (
    <g transform={`translate(${x(data.key)}, ${y(flag === 'capacity' ? data.count : data.value)})`} style={{ cursor: 'pointer' }}>
      <rect
        width={x.bandwidth()}
        height={height - bottom - top - y(flag === 'capacity' ? data.count : data.value)}
        fill={flag === 'capacity' ? capacityColors(index) : colors(index)}
        onMouseOver={onMouseOverCallback}
        onMouseOut={onMouseOutCallback}
        onClick={onClickCallback}
      />
      <text
        transform={`translate(${x.bandwidth() / 2}, ${-2})`}
        textAnchor="middle"
        alignmentBaseline="text-bottom"
        fill="grey"
        fontSize="10"
      >
        {flag === 'capacity' ? data.count : data.value}
      </text>
    </g>
  );
};

const Bar = (props) => {
  const [hoveredBar, setHoveredBar] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedData, setClickedData] = useState();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const exportToCsv = (category, filterOn) => {
    props.setIsConfirmationModal(true)
    const date = new Date()
    let params = {}
    const filename = `${category}_records_${getFormattedDate(date)}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.xlsx`
    if (filterOn === 'parentSponsor') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          sponsor: [category],
          date: props.dateFilter,
        }),
      }
    }
    if (filterOn === 'parentPhase') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          phase: [category],
          date: props.dateFilter,
        }),
      }
    }
    if (filterOn === 'parentIndication') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          indication: [category],
          date: props.dateFilter,
        }),
      }
    }
    if (filterOn === 'parentAgeRange') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          age_range: [category],
          date: props.dateFilter,
        }),
      }
    }
    if (filterOn === 'parentStudiesTA') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          therapeutic_area: [category],
          date: props.dateFilter,
        }),
      }
    }
    if (filterOn === 'ParentLast2Years' || filterOn === 'ParentLast5Years' || filterOn === 'parentStatus') {
      params = {
        id: parse(location.search).id,
        alliance_type: props.allianceType,
        filters: JSON.stringify({
          status: [category],
          date: props.dateFilter,
        }),
      }
    }

    Axios({
      url: `${process.env.apiUrl}/v0/export/exportInstitutionTrialsData?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        // 'Content-Disposition': `attachment;filename=Data.${
        //   filetype === 'CSV' ? 'csv' : 'xlsx'
        // }`,
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      })
  }

  const { data } = props;
  const x = d3
    .scaleBand()
    .range([0, props.width - props.left - props.right])
    .domain(data.map((d) => d.key))
    .padding(0.15);

  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 0])
    .domain([0, d3.max(data, (d) => { return (props.flag === 'capacity') ? d.count : d.value })])

  return (
    <>
      <svg width={props.width} height={props.height}>
        <XAxis
          scale={x}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          height={props.height}
          hideLabels={props.hideLabels}
        />
        <YAxis
          scale={y}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
        />
        <g transform={`translate(${props.left}, ${props.top})`}>
          {data.map((d, i) => (
            <Rect
              data={d}
              flag={props.flag}
              index={i}
              x={x}
              y={y}
              svg
              top={props.top}
              bottom={props.bottom}
              height={props.height}
              onMouseOverCallback={() => { setHoveredBar(d) }}
              onMouseOutCallback={() => setHoveredBar(null)}
              onClickCallback={() => {
                setIsModalOpen((old) => !old);
                setClickedData(d);
              }}
            />
          ))}
          {props.flag === 'capacity' && (
            <text
              transform={`translate(${x.bandwidth() / 2 - 100}, ${120})`}
              textAnchor="middle"
              alignmentBaseline="text-bottom"
              fill="grey"
              fontSize="10"
            >
              No. of studies
            </text>
          )}
        </g>
      </svg>
      {hoveredBar
        ? (
          <Tooltip
            hoveredBar={hoveredBar}
            scales={{ x, y }}
          />
        )
        : null}

      {props.showModal ? (
        <Modal
          title={`${props.titleName}: ${clickedData?.category}`}
          open={isModalOpen}
          onCancel={handleCancel}
          destroyOnClose
          footer={null}
          className="showstudiesdata-modal"
        >
          <div
            className="export-btn-large export-btn-modal graph-head-icon"
            onClick={() => exportToCsv(clickedData.category, props.studies)}
            role="presentation"
          />
          <ShowStudiesData clickedData={clickedData} filterOn={props.studies} dataset={props.checkDataset} pid={props.pi_id} dateFilter={props.dateFilter} />
        </Modal>
      ) : null}

      {props.showCenter ? (
        <Modal
          title={`${props.titleName}: ${clickedData?.category}`}
          open={isModalOpen}
          onCancel={handleCancel}
          destroyOnClose
          footer={null}
          className="showstudiesdata-modal"
        >
          <ShowCentersData clickedData={clickedData} filterOn={props.studies} dataset={props.checkDataset} pid={props.pi_id} />
        </Modal>
      ) : null}
    </>
  );
};

export default Bar;
